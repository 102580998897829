<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Message Category {{` ${modalInfo.categoryid}`}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
        <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.categoryid"
                        label="ID"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form.messagetype.selected"
                        :items="form.messagetype.items"
                        :rules="form.messagetype.rule"
                        item-text="name"
                        item-value="id"
                        label="Select Message Type"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.categoryname.item"
                        label="Message Category"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.categorycreateddatetime"
                        label="Created Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.categorycreatedbyadminname"
                        label="Created By"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.categoryupdateddatetime"
                        label="Updated Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.categoryupdatedbyadminname"
                        label="Last Update Person"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn v-if="validateAccess('mc_delete')" tile color="error darken-1" small @click="handleDelete('delete')" class="ml-2">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                categoryname: {item: '', rule: []},
                messagetype: {items: [
                        {id: null, name: 'Select'},
                        {id: 1, name: 'Inbox'},
                        {id: 2, name: 'Notice'}
                    ], rule: [], selected: null},
            },

            btn_loading: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.categoryname.rule = [];
        },

        async beforeCreateForm(flag){
            this.form.messagetype.rule = [
                v => !!v || 'This is required',
            ];

            this.form.categoryname.rule = [
                v => !!v || 'This is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.onRuleChange();
            this.form.messagetype.selected = this.modalInfo.notiTypeID;
            this.form.categoryname.item = this.modalInfo.categoryname;
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>